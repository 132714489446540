'use client';
import React from 'react';

import styled from 'styled-components';

import useOutsideClick from '../hooks/useOutsideClick';
import { useSearchContext } from '../SearchContext';
import { baseTheme } from '@/theme';

const Wrapper = styled.div<{ $maxWidth?: string; $borderColor?: string }>`
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || '35rem'};
  height: 3.5rem;
  position: relative;
  margin: auto;
  border: 1px solid
    ${({ $borderColor }) => $borderColor || baseTheme.custom.colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 2;
`;
// ${({ theme }) => theme.breakpoints.down('md')} {
//   width: auto;
// }

export default function SearchContainer({ maxWidth, borderColor, children }) {
  const wrapperRef = React.useRef(null);
  const { setAreSuggestionsVisible } = useSearchContext();

  useOutsideClick(wrapperRef, () => {
    setAreSuggestionsVisible(false);
  });

  return (
    <Wrapper ref={wrapperRef} $maxWidth={maxWidth} $borderColor={borderColor}>
      {children}
    </Wrapper>
  );
}
