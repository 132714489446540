import React from 'react';

const useShowSuggestionsOnFocus = (
  inputFocused,
  suggestions,
  activeSuggestionIndex,
  setAreSuggestionsVisible
) => {
  React.useEffect(() => {
    if (inputFocused && suggestions?.length) {
      setAreSuggestionsVisible(true);
    }
  }, [
    inputFocused,
    suggestions?.length,
    activeSuggestionIndex,
    setAreSuggestionsVisible,
  ]);
};

export default useShowSuggestionsOnFocus;
