import React from 'react';

interface SearchContextType {
  inputValue: string;
  suggestions: Suggestions;
  setInputValue: (value: string) => void;
  setSuggestions: (suggestions: Suggestions) => void;
  clearInput: () => void;
  inputRef: React.RefObject<HTMLInputElement> | null;
  inputFocused: boolean;
  setInputFocused: (focused: boolean) => void;
  searchQuery: string;
  areSuggestionsVisible: boolean;
  activeSuggestionIndex: number | null;
  setActiveSuggestionIndex: (index: number | null) => void;
  goToSearchPageAction: (query?: string | null) => void;
  logSelectedSuggestion: (suggestion: string) => void;
  blurInput: () => void;
  setAreSuggestionsVisible: (visible: boolean) => void;
  // handleSuggestionClick: (index: number) => void;
}

const defaultValues: SearchContextType = {
  inputValue: '',
  suggestions: [],
  setInputValue: () => {},
  setSuggestions: () => {},
  clearInput: () => {},
  inputRef: null,
  inputFocused: false,
  setInputFocused: () => {},
  searchQuery: '',
  areSuggestionsVisible: false,
  activeSuggestionIndex: null,
  setActiveSuggestionIndex: () => {},
  goToSearchPageAction: () => {},
  logSelectedSuggestion: () => {},
  blurInput: () => {},
  setAreSuggestionsVisible: () => {},
  // handleSuggestionClick: () => {},
};

export const SearchContext =
  React.createContext<SearchContextType>(defaultValues);

export const useSearchContext = () => React.useContext(SearchContext);
