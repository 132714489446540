import styled from 'styled-components';

export const SearchInput = styled.input<{
  $areSuggestionsVisible: boolean;
  placeholder: string;
}>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${({ placeholder }) =>
    placeholder ? '0.375rem 0.75rem' : '1.7em 1em 0.5em 0.5em'};
  outline: none;
  background-color: transparent;

  /* text */
  font-weight: bold;
  font-size: 1rem;
  line-height: normal;

  /* border */
  border-color: ${({ theme, $areSuggestionsVisible }) =>
    $areSuggestionsVisible ? theme.custom.colors.brand : 'white'};
  border-width: 2px;
  border-style: solid;
  border-radius: 0.19rem;
  padding-right: 6rem;

  /* placeholder */
  &::placeholder {
    color: ${({ theme }) => theme.custom.colors.gray500};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const Wrapper = styled.div<{ $withBorder: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: ${({ $withBorder, theme }) =>
    $withBorder ? `1px solid ${theme.custom.colors.gray200}` : 'none'};
  border-radius: 0.1875rem;
`;

export const InputLabel = styled.label<{ $hasValue: boolean }>`
  color: var(--Gray, #808080);
  position: absolute;
  top: 0.2rem;
  left: 0.6rem;
  pointer-events: none;
  font-size: 0.875rem;
  color: #777;
  font-weight: 400;
  transition: 0.2s ease all;
  ${({ $hasValue }) => $hasValue && 'transform: translateY(-1.5em) scale(0.8);'}
`;

export const Buttons = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 0.5em;
`;
