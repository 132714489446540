'use client';

import React from 'react';

import { useRouter } from 'next/navigation';
import { useLocale } from 'next-intl';

import useHandleKeyDown from './hooks/useHandleKeyDown';
import useLogSelectedSuggestion from './hooks/useLogSelectedSuggestion';
import useResetActiveSuggestionIndex from './hooks/useResetActiveSuggestionIndex';
import useSetInputValueFromSuggestion from './hooks/useSetInputValueFromSuggestion';
import useShowSuggestionsOnFocus from './hooks/useShowSuggestionsOnFocus';
import { SearchContext } from './SearchContext';
import persistentState, { PERSISTENT_STATE_KEYS } from '@/lib/persistentState';
// import { get } from '@/utils/queryData';
// import request from '@/utils/queryData/rest/clientRequest';

const PERSISTENT_STATE_KEY = PERSISTENT_STATE_KEYS.searchHistory;

const PATH_TO_SEARCH = '/search';

export const goToSearchPage = ({ router, query, searchPath = '', locale }) => {
  const trimmedQuery = query.trim();

  if (!trimmedQuery?.length) return;

  const previousHistory = persistentState.cookie.get({
    key: PERSISTENT_STATE_KEY,
  });

  if (!Array.isArray(previousHistory)) {
    persistentState.cookie.set({
      key: PERSISTENT_STATE_KEY,
      value: JSON.stringify([trimmedQuery]),
    });
  }

  if (
    Array.isArray(previousHistory) &&
    !previousHistory.includes(trimmedQuery)
  ) {
    persistentState.cookie.set({
      key: PERSISTENT_STATE_KEY,
      value: JSON.stringify([...previousHistory, trimmedQuery]),
    });
  }

  router.push(
    `/${locale}/${searchPath || PATH_TO_SEARCH}/${encodeURIComponent(
      trimmedQuery
    )}`
  );
};

const SearchContextProvider = ({ children, defaultValue = '', searchPath }) => {
  const [searchQuery, setSearchQuery] = React.useState<string>(''); // defaultValue,  search query - separate from input value. Used for search page

  // search input value
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = React.useState<string>(defaultValue); // search input value
  const [inputFocused, setInputFocused] = React.useState<boolean>(false); // is input focused

  // suggestions
  const [suggestions, setSuggestions] = React.useState<Suggestions>([]); // suggestions as ibjects
  const [areSuggestionsVisible, setAreSuggestionsVisible] =
    React.useState(false); // are suggestions visible
  const [activeSuggestionIndex, setActiveSuggestionIndex] = React.useState<
    number | null
  >(null); // active suggestion index for keyboard navigation

  const router = useRouter();
  const locale = useLocale();

  const changeActiveSuggestion = React.useCallback(
    ({ event, direction }) => {
      if (areSuggestionsVisible && suggestions.length > 0) {
        event.preventDefault();

        if (direction === 'up') {
          setActiveSuggestionIndex(prevIndex => {
            if (prevIndex === null) return suggestions.length - 1;
            if (prevIndex === 0) return suggestions.length - 1;
            return prevIndex - 1;
          });
        } else if (direction === 'down') {
          setActiveSuggestionIndex(prevIndex => {
            if (prevIndex === null) return 0;
            if (prevIndex === suggestions.length - 1) return 0;
            return prevIndex + 1;
          });
        }
      }
    },
    [suggestions, areSuggestionsVisible]
  );

  // go to search page
  const goToSearchPageAction = React.useCallback(
    (query: undefined | string) => {
      const queryValue = query || inputValue;
      goToSearchPage({ router, query: queryValue, searchPath, locale });
    },
    [router, inputValue]
  );

  // handle key actions on the search input
  useHandleKeyDown({
    changeActiveSuggestion,
    goToSearchPageAction,
    suggestions,
    activeSuggestionIndex,
    setInputValue,
    areSuggestionsVisible,
    setAreSuggestionsVisible,
    setActiveSuggestionIndex,
    searchQuery,
    setSearchQuery,
    inputValue,
    inputRef,
  });

  /**
   * Callbacks
   */

  // clear input value and search query
  const clearInput = React.useCallback(() => {
    setInputValue('');
    setSearchQuery('');
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current?.focus?.();
    }
  }, []);

  /**
   * @todo
   */
  const logSelectedSuggestion = React.useCallback(
    () => {},
    // async suggestion => {
    //   /**
    //    * @toto handle response
    //    */
    //   // await get.on('client').withParams({
    //   //   input: searchQuery,
    //   //   suggestion: suggestion,
    //   //   locale: router.locale,
    //   // });
    //   await new request('analytics').get({
    //     input: searchQuery,
    //     suggestion: suggestion,
    //     locale,
    //   });
    // },
    [searchQuery, suggestions, activeSuggestionIndex, locale]
  );

  const focusInput = React.useCallback(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  const blurInput = React.useCallback(() => {
    if (inputRef.current) inputRef.current.blur();
  }, [inputRef]);

  /**
   * Effects
   */

  // show suggestions when input is focused
  // React.useEffect(() => {
  //   if (typeof searchQuery === 'string') {
  //     setAreSuggestionsVisible(true);
  //   } else {
  //     setAreSuggestionsVisible(false);
  //   }
  // }, [searchQuery, inputFocused]);

  // reset active suggestion index when search query changes
  useResetActiveSuggestionIndex({
    searchQuery,
    setActiveSuggestionIndex,
  });

  // log selected suggestion
  useLogSelectedSuggestion({
    activeSuggestionIndex,
    logSelectedSuggestion,
    focusInput,
  });

  // set input value to the value of the active suggestion
  useSetInputValueFromSuggestion(
    activeSuggestionIndex,
    suggestions,
    setInputValue,
    inputRef
  );

  useShowSuggestionsOnFocus(
    inputFocused,
    suggestions,
    activeSuggestionIndex,
    setAreSuggestionsVisible
  );

  return (
    <SearchContext.Provider
      value={{
        inputValue,
        setInputValue,
        clearInput,
        inputRef,
        inputFocused,
        setInputFocused,
        searchQuery,
        suggestions,
        setSuggestions,
        areSuggestionsVisible,
        activeSuggestionIndex,
        setActiveSuggestionIndex,
        goToSearchPageAction,
        logSelectedSuggestion,
        blurInput,
        setAreSuggestionsVisible,
        // handleSuggestionClick,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export { SearchContext, SearchContextProvider };
