'use client';
import React from 'react';

import SearchContainer from './components/SearchContainer';
import SearchField from './components/SearchField';
import Suggestions from './components/Suggestions';
import { SearchContextProvider } from './SearchContextProvider';
import { LocaleType } from '@/i18n';

const maxWidthMap = new Map([
  [true, '45.625rem'],
  [false, '35rem'],
]);

interface SearchWithSuggestionsProps {
  defaultValue?: string;
  placeholder?: string;
  frontpagePlaceholder?: string;
  borderColor?: string;
  searchPath?: string;
  locale: LocaleType;
}

/**
 * @todo global props,
 * @prop placeholder modifies the SearchField visuals and max-width of the container
 * @todo refactor - frontpage placeholder
 */
export default function SearchWithSuggestions({
  defaultValue = '',
  placeholder = '',
  frontpagePlaceholder = '',
  borderColor = '',
  searchPath = '',
  locale,
}: SearchWithSuggestionsProps) {
  return (
    <SearchContextProvider
      defaultValue={decodeURIComponent(defaultValue)}
      searchPath={searchPath}
    >
      <SearchContainer
        maxWidth={maxWidthMap.get(!!placeholder || !!defaultValue)}
        borderColor={borderColor}
      >
        <SearchField
          placeholder={placeholder}
          frontpagePlaceholder={frontpagePlaceholder}
          searchPath={searchPath}
          locale={locale}
        />
        <Suggestions />
      </SearchContainer>
    </SearchContextProvider>
  );
}
