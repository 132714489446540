import React from 'react';

const useSetInputValueFromSuggestion = (
  activeSuggestionIndex,
  suggestions,
  setInputValue,
  inputRef
) => {
  React.useEffect(() => {
    if (Number.isInteger(activeSuggestionIndex)) {
      const suggestion = suggestions[activeSuggestionIndex];

      setInputValue(suggestion?.value);

      if (inputRef.current) inputRef.current.value = suggestion?.value;
    }
  }, [activeSuggestionIndex, suggestions, setInputValue]);
};

export default useSetInputValueFromSuggestion;
