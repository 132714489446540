import React from 'react';

const useLogSelectedSuggestion = ({
  activeSuggestionIndex,
  logSelectedSuggestion,
  focusInput,
}) => {
  React.useEffect(() => {
    if (Number.isInteger(activeSuggestionIndex)) {
      focusInput();

      const timeout = setTimeout(() => {
        logSelectedSuggestion();
      }, 700);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [activeSuggestionIndex, logSelectedSuggestion, focusInput]);
};

export default useLogSelectedSuggestion;
