import { createTheme } from '@mui/material/styles';

// import { SHOULD_USE_CDN, CDN_URL } from '@/constants';
import { theme as prusaUiTheme } from '@/theme.prusaUi';

const FONT_URL_PREFIX = ''; // SHOULD_USE_CDN ? CDN_URL : '';

export const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#fa6831',
      // main: tokens.ColorBrandPrimary,
      dark: '#e05d2d',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      main: '#000000',
    },
    text: {
      primary: '#2a2a2a',
      secondary: '#5c5c5c',
      disabled: '#808080',
    },
    error: {
      main: '#FF0000',
      dark: '#CB2C2C',
      light: '#E84040',
    },
    success: {
      main: '#5DAF0B',
      light: '#65C900',
    },
    info: {
      main: '#26B4D5',
      light: '#40C8E8',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },

    gray: {
      main: '#E0E0E0',
      light: '#F5F5F5',
      dark: '#808080',
    },

    // colors: {
    //   white: '#ffffff',

    //   brand: '#fa6831',
    //   brandDark: '#e05d2d',

    //   yellow: '#F2BA0E',
    //   yellow400: '#F2BA0E',
    //   yellow500: '#9c8408',

    //   gray100: '#F5F5F5',
    //   gray200: '#E0E0E0',
    //   gray300: '#B3B3B3',
    //   gray400: '#8C8C8C',
    //   gray500: '#808080',
    //   gray700: '#5C5C5C',
    //   gray800: '#2A2A2A',

    //   blue400: '#40C8E8',
    //   blue500: '#26B4D5',

    //   green400: '#65C900',
    //   green500: '#5DAF0B',

    //   red300: '#E05D2D',
    //   red400: '#E84040',
    //   red500: '#CB2C2C',
    //   red700: '#FF0000',

    //   black: '#000000',
    // },
  },
  shape: {
    borderRadius: 2,
  },
  spacing: 10,
  typography: {
    fontSize: 14,
    fontFamily: ['Atlas Grotesk', 'sans-serif', '-apple-system'].join(','),

    h1: {
      fontSize: '48px',
      fontWeight: 'bold',
      lineHeight: '58px',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '38px',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: '29px',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '26px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '19px',
    },
    h6: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '17px',
    },

    // subtitle1: {
    //   fontSize: '13px',
    //   lineHeight: '16px',
    // },
    // subtitle2: {
    //   fontSize: '12px',
    //   lineHeight: '14px',
    // },

    body1: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    body2: {
      fontSize: '16px',
      lineHeight: '30px',
    },

    caption: {
      fontSize: '12px',
      lineHeight: '17px',
    },

    // button
    // overline
  },
  components: {
    MuiCssBaseline: {
      // TODO: https://gitlab.com/prusa3d-platform/content/javascript-libraries/mui-theme/-/blob/main/src/theme.ts?ref_type=heads
      styleOverrides: `
        @font-face {
          font-family: 'Atlas Grotesk';
          src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Regular-Web.woff2') format('woff2'),
              url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Regular-Web.woff') format('woff');
          font-style: normal;
          font-weight: 400;
          font-display: swap;
        }
        @font-face {
          font-family: 'Atlas Grotesk';
          src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-RegularItalic-Web.woff2') format('woff2'),
              url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-RegularItalic-Web.woff') format('woff');
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'Atlas Grotesk';
          src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Bold-Web.woff2') format('woff2'),
              url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Bold-Web.woff') format('woff');
          font-weight: bold;
          font-display: swap;
        }
        @font-face {
          font-family: 'Atlas Grotesk';
          src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Black-Web.woff2') format('woff2'),
              url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Black-Web.woff') format('woff');
          font-weight: 900;
          font-display: swap;
        }

        .grecaptcha-badge {
          visibility: hidden !important;
        }
      `,
    },
    MuiButton: {
      // deprecated
      // defaultProps: {
      //   variant: 'contained',
      // },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: 'white',
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.dark}`,
          boxShadow: 'none',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: 1.2,
          textTransform: 'none',

          svg: {
            // fortawesome doesnt respect startIcon/endIcon settings
            fontSize: ownerState.iconSize
              ? `${ownerState.iconSize}px !important`
              : '20px',
          },

          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
            boxShadow: 'none',
          },
        }),
        startIcon: ({ ownerState }) => ({
          marginLeft: 0,
          marginRight: ownerState.children
            ? ownerState.iconSize
              ? '16px'
              : '8px'
            : 0,
        }),
        endIcon: ({ ownerState }) => ({
          marginLeft: ownerState.children
            ? ownerState.iconSize
              ? '16px'
              : '8px'
            : 0,
          marginRight: 0,
        }),
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            padding: '8px 9px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '9px 19px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: '16px',
            padding: '9px 19px',
          },
        },
        {
          props: { size: 'xlarge' },
          style: {
            fontSize: '20px',
            padding: '14px 30px',
          },
        },

        {
          props: { color: 'primary' },
          style: {
            fontWeight: 700,
          },
        },

        {
          props: { color: 'secondary' },
          style: ({ theme }) => ({
            background: theme.palette.background.default,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,

            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              color: theme.palette.background.default,
            },
          }),
        },

        {
          props: { variant: 'text' },
          style: ({ theme }) => ({
            background: 'transparent',
            border: 'none',
            color: theme.palette.text.primary,
            padding: 0,
            fontWeight: 400,
            textDecoration: 'underline',

            svg: {
              color: theme.palette.primary.main,
            },

            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'none',
            },
          }),
        },

        {
          props: { variant: 'outlined', color: 'primary' },
          style: ({ theme }) => ({
            background: theme.palette.gray.light,
            borderColor: theme.palette.gray.main,
            color: theme.palette.text.primary,
            fontWeight: 400,

            '&:hover': {
              backgroundColor: theme.palette.gray.main,
              borderColor: theme.palette.gray.main,
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: ({ theme }) => ({
            background: theme.palette.background.default,
            borderColor: theme.palette.gray.main,
            color: theme.palette.text.primary,
            fontWeight: 400,

            '&:hover': {
              backgroundColor: theme.palette.gray.main,
              borderColor: theme.palette.gray.main,
              color: theme.palette.text.primary,
            },
          }),
        },
      ],
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: '15px',
          marginRight: '14px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.gray.main,
          borderRadius: '3px',
          fontSize: '10px',
          lineHeight: '12px',
          height: 'auto',
          paddingBottom: '5px',
          paddingTop: '6px',
          textTransform: 'uppercase',
        }),
        label: {
          paddingLeft: '10px',
          paddingRight: '10px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          '& textarea': {
            backgroundColor: theme.palette.background.default,
          },
        }),
      },
    },
    // MuiTypography: {
    //   defaultProps: {
    //     variantMapping: {
    //       h13: 'h2',
    //     },
    //   },
    // },
  },
  custom: prusaUiTheme,
});

// const theme = createTheme(baseTheme, {
//   typography: {
//     h1: {
//       [baseTheme.breakpoints.down('md')]: {
//         fontSize: '24px',
//         lineHeight: '34px',
//       },
//     },
//   },
// });
// export default theme;

export const setTheme = () => {
  /**
   * @todo examine
   */
  // { isMedical }
  // if (isMedical) {
  //   baseTheme.palette.primary.main = '#83d5d3';
  //   baseTheme.custom.colors.brand = '#83d5d3';
  // }

  const theme = createTheme(baseTheme, {
    typography: {
      h1: {
        [baseTheme.breakpoints.down('md')]: {
          fontSize: '24px',
          lineHeight: '34px',
        },
      },
    },
  });

  return theme;
};
