import React from 'react';

import { useQuery } from '@tanstack/react-query';

import persistentState, { PERSISTENT_STATE_KEYS } from '@/lib/persistentState';
import { normalize as normalizeString } from '@/utils/normalize';
import request from '@/utils/queryData/rest/clientRequest';

export const useSearch = (searchQuery, locale) => {
  const queryKey = ['suggestions', searchQuery, locale];
  const queryFn = async ({ signal }) => {
    const data = await new request('search').with(signal).get({
      query: searchQuery,
      locale,
    });

    return data;
  };

  return useQuery({ queryKey, queryFn });
};

const getUserSearchHistory = () => {
  const searchHistoryJson = persistentState.cookie.get({
    key: PERSISTENT_STATE_KEYS.searchHistory,
  }) as string[] | null;

  return searchHistoryJson || null;
};

function SuggestionObject({ type, value }) {
  this.type = type;
  this.value = value;
}

const getFrequentSearchHistoryItem = searchHistory => {
  // add gistory item which repeats the most times
  const mostFrequentSearchHistoryItem = searchHistory.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});

  const mostFrequentSearchHistoryItemValue = Object.keys(
    mostFrequentSearchHistoryItem
  ).reduce((a, b) =>
    mostFrequentSearchHistoryItem[a] > mostFrequentSearchHistoryItem[b] ? a : b
  );

  return mostFrequentSearchHistoryItemValue;
};

export const useSmartSuggestions = (
  fetchedSuggestions,
  setSmartSuggestions
) => {
  React.useEffect(() => {
    const newSuggestions: Suggestions = [];

    if (Array.isArray(fetchedSuggestions) && fetchedSuggestions.length > 0) {
      fetchedSuggestions.map(suggestion => {
        const suggestionObject = new SuggestionObject({
          type: 'suggestion',
          value: suggestion,
        });
        newSuggestions.push(suggestionObject);
      });

      setSmartSuggestions(newSuggestions);
    }
  }, [fetchedSuggestions, setSmartSuggestions]);
};

type SearchHistory = string[] | null;

export const useSearchHistorySuggestions = (searchQuery, setSearchHistory) => {
  React.useEffect(() => {
    let newSuggestions: Suggestions = [];
    const userSearchHistory: SearchHistory = getUserSearchHistory();

    if (userSearchHistory?.length) {
      if (typeof userSearchHistory === 'string') {
        persistentState.cookie.delete({
          key: PERSISTENT_STATE_KEYS.searchHistory,
        });
        return;
      }

      if (searchQuery.length === 0) {
        userSearchHistory.slice(0, 3).map(searchHistoryItem => {
          const suggestionObject = new SuggestionObject({
            type: 'history',
            value: searchHistoryItem,
          });
          newSuggestions.push(suggestionObject);
        });

        const mostFrequentSearchHistoryItemValue =
          getFrequentSearchHistoryItem(userSearchHistory);

        const suggestionObject = new SuggestionObject({
          type: 'history',
          value: mostFrequentSearchHistoryItemValue,
        });

        newSuggestions.push(suggestionObject);

        // remove duplicates
        newSuggestions = newSuggestions.filter(
          (suggestion, index, self) =>
            index === self.findIndex(t => t.value === suggestion.value)
        );
      } else {
        if (Array.isArray(userSearchHistory)) {
          userSearchHistory.map(searchHistoryItem => {
            if (typeof searchHistoryItem === 'string') {
              if (searchHistoryItem.includes(searchQuery)) {
                const suggestionObject = new SuggestionObject({
                  type: 'history',
                  value: searchHistoryItem,
                });
                newSuggestions.push(suggestionObject);
              }
            }
          });
        }
      }

      newSuggestions.reverse();
      newSuggestions.splice(3);
      setSearchHistory(newSuggestions);
    }
  }, [searchQuery, setSearchHistory]);
};

const getUniqueSuggestions = suggestions => {
  const uniqueSuggestions = suggestions.filter(
    (suggestion, index, self) =>
      index ===
      self.findIndex(
        t => normalizeString(t.value) === normalizeString(suggestion.value)
      )
  );

  return uniqueSuggestions;
};

export const useCombinedSuggestions = (
  searchQuery,
  searchHistory,
  smartSuggestions,
  setSuggestions
) => {
  const [_isPenging, startTransition] = React.useTransition();

  React.useEffect(() => {
    if (searchQuery.length === 0) {
      const newSuggestions = getUniqueSuggestions(searchHistory);
      startTransition(() => {
        setSuggestions(newSuggestions);
      });
    } else {
      const newSuggestions = getUniqueSuggestions([
        ...searchHistory,
        ...smartSuggestions,
      ]);
      startTransition(() => {
        setSuggestions(newSuggestions);
      });
    }
  }, [searchQuery, searchHistory, smartSuggestions, setSuggestions]);
};
