import React from 'react';

import styled from 'styled-components';

const FontWeightNormal = styled.span`
  font-weight: normal !important;
`;

// return part matching inputValue with bold and do it for every word of the suggestion
export const parseSuggestion = (suggestion, inputValue) => {
  if (typeof suggestion !== 'string' || typeof inputValue !== 'string') {
    return '👻';
  }

  // if the input value is empty, return the suggestion in normal font weight
  if (inputValue === '') {
    return <FontWeightNormal>{suggestion}</FontWeightNormal>;
  }

  const words = suggestion.split(' ');
  return words.map((word, index) => {
    if (word.toLowerCase().startsWith(inputValue.toLowerCase())) {
      return (
        <span key={index}>
          <FontWeightNormal key={index}>
            {word.substring(0, inputValue.length)}
          </FontWeightNormal>
          {word.substring(inputValue.length)}&nbsp;
        </span>
      );
    }
    return <>{word}&nbsp;</>;
  });
};
