'use client';

import React, { useState } from 'react';

import { useLocale } from 'next-intl';
import styled from 'styled-components';
// import { useRouter } from 'next/router';

import SuggestionsList from './SuggestionsList';
import useEventSource from '../hooks/useEventSource';
import {
  useSmartSuggestions,
  useSearchHistorySuggestions,
  useCombinedSuggestions,
} from '../hooks/useSuggestions';
import { useSearchContext } from '../SearchContext';

const Wrapper = styled.div`
  position: absolute;
  top: -0.62rem;
  left: -0.62rem;
  right: -0.62rem;
  border-radius: 0.31rem;
  background-color: white;
  z-index: 1;
  padding-top: 4.75rem;
  color: #000;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
`;

const Suggestions = () => {
  const [smartSuggestions, setSmartSuggestions] = useState<Suggestions>([]);
  const [searchHistory, setSearchHistory] = useState<Suggestions>([]);

  const locale = useLocale();

  const {
    searchQuery,
    areSuggestionsVisible,
    setSuggestions,
    // suggestions,
    // inputFocused,
    // activeSuggestionIndex,
  } = useSearchContext();

  const streamData = useEventSource({
    query: searchQuery,
    locale,
  });

  // const {
  //   data: fetchedSuggestions,
  //   // isLoading: isLoadingSuggestions, // isLoading={isLoadingSuggestions}
  //   isError: errorFetching,
  // } = useSearch(searchQuery, locale);

  useSmartSuggestions(streamData, setSmartSuggestions);

  useSearchHistorySuggestions(searchQuery, setSearchHistory);

  useCombinedSuggestions(
    searchQuery,
    searchHistory,
    smartSuggestions,
    setSuggestions
  );

  // errorFetching
  if (areSuggestionsVisible === false) return null;

  return (
    <Wrapper>
      <SuggestionsList />
    </Wrapper>
  );
};

export default Suggestions;
