/**
 * @description Normalize a string by removing diacritics and converting to lowercase
 * @example normalize('Čeština') // 'cestina'
 * @example normalize('¯\_(ツ)_/¯') // '¯\_(ツ)_/¯'
 */
export const normalize = (str: unknown) => {
  if (!str) return '';
  if (typeof str === 'object') return '';

  let normalizedString = '';

  if (typeof str === 'number') normalizedString = str.toString();

  return normalizedString
    .normalize('NFD') // Convert accented characters to decomposed form
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .toLowerCase();
};
