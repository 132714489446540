'use client';

import React from 'react';

interface keyDownActionsProps {
  event: KeyboardEvent;
  activeSuggestionIndex: number | null;
  suggestions: Suggestions;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setAreSuggestionsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  inputValue: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  inputRef: React.RefObject<HTMLInputElement>;
  setActiveSuggestionIndex: React.Dispatch<React.SetStateAction<number | null>>;
  areSuggestionsVisible: boolean;
  changeActiveSuggestion: ({ event, direction }) => void;
  goToSearchPageAction: (query: string) => void;
}

const handleEnterKey = ({
  goToSearchPageAction,
  activeSuggestionIndex,
  suggestions,
  setInputValue,
  setAreSuggestionsVisible,
  inputValue,
}) => {
  if (activeSuggestionIndex === null) return goToSearchPageAction(inputValue);
  const suggestion = suggestions[activeSuggestionIndex];
  if (suggestion?.value) {
    setInputValue(suggestion.value);
    setAreSuggestionsVisible(false);
    goToSearchPageAction(suggestion.value);
  }
};

const handleEscapeKey = ({
  activeSuggestionIndex,
  setSearchQuery,
  inputValue,
  setActiveSuggestionIndex,
  setAreSuggestionsVisible,
}) => {
  if (Number.isInteger(activeSuggestionIndex)) {
    setSearchQuery(inputValue);
    setActiveSuggestionIndex(null);
  } else {
    setAreSuggestionsVisible(false);
  }
};

/**
 * @todo refactor this mess
 */
const keyDownActions = ({
  event,
  changeActiveSuggestion,
  ...rest
}: keyDownActionsProps) => {
  const actions = {
    ArrowUp: () =>
      changeActiveSuggestion({
        direction: 'up',
        event,
      }),
    ArrowDown: () =>
      changeActiveSuggestion({
        direction: 'down',
        event,
      }),
    Enter: () => {
      handleEnterKey({ ...rest });
    },
    Escape: () => {
      handleEscapeKey({ ...rest });
    },
  };

  if (actions[event.key]) {
    actions[event.key]();
  } else {
    if (rest.inputRef.current !== null)
      rest.setSearchQuery(rest.inputRef.current.value);
  }
};

/**
 * @description on key down events - arrows up/down, enter, escape
 */
const useHandleKeyDown = props => {
  const handleEvent = React.useCallback(
    event => {
      keyDownActions({ ...props, event });
    },
    [props]
  );

  // Update inputValue state on each key press
  const handleInputChange = React.useCallback(event => {
    props.setInputValue(event.target.value);
  }, []);

  React.useEffect(() => {
    const eventName = 'keyup';
    const inputElement = props.inputRef?.current;

    if (inputElement) {
      inputElement.addEventListener(eventName, handleEvent);
      inputElement.addEventListener('input', handleInputChange);
      return () => {
        inputElement.removeEventListener(eventName, handleEvent);
        inputElement.removeEventListener('input', handleInputChange);
      };
    }
  }, [handleEvent, props.inputRef, handleInputChange]);
};

export default useHandleKeyDown;
