'use client';

import React from 'react';

import { useTranslations } from 'next-intl';

import ClearButton from './ClearButton';
import SearchButton from './SearchButton';
import {
  InputLabel,
  SearchInput,
  Wrapper,
  Buttons,
} from './SearchField.styled';
import { useSearchContext } from '../SearchContext';

/**
 * @todo refactor - frontpage placeholder
 */
export default function SearchField({
  placeholder = '',
  frontpagePlaceholder = '',
  searchPath,
  locale,
}) {
  const {
    inputValue,
    inputRef,
    setInputFocused,
    areSuggestionsVisible,
    // inputFocused,
    // setInputValue,
  } = useSearchContext();
  const t = useTranslations();

  // const onChangeAction = React.useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setInputValue(event.target.value);
  //   },
  //   []
  // );

  return (
    <Wrapper $withBorder={!!placeholder}>
      {!placeholder && !frontpagePlaceholder && (
        <InputLabel htmlFor="search" $hasValue={false}>
          {t('homepage-search')}
        </InputLabel>
      )}

      <SearchInput
        id="search"
        type="text"
        aria-label="Search"
        placeholder={placeholder || frontpagePlaceholder}
        defaultValue={inputValue}
        // onChange={onChangeAction}
        ref={inputRef}
        autoComplete="off"
        $areSuggestionsVisible={areSuggestionsVisible}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        tabIndex={0}
      />

      <Buttons>
        <ClearButton />
        <SearchButton searchPath={searchPath} locale={locale} />
      </Buttons>
    </Wrapper>
  );
}
