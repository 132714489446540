'use client';

import React from 'react';

import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import styled from 'styled-components';

import { useSearchContext } from '../SearchContext';
import { goToSearchPage } from '../SearchContextProvider';

const SearchButtonWrapper = styled.button`
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Gray, #808080);
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }

  svg {
    font-size: 1.875rem;
  }
`;

const SearchButton = ({ searchPath, locale }) => {
  const router = useRouter();
  const translate = useTranslations();

  const { inputValue, searchQuery } = useSearchContext();

  const onClickAction = React.useCallback(() => {
    // if (!searchQuery?.length) return;
    goToSearchPage({
      router,
      query: inputValue || searchQuery,
      searchPath,
      locale,
    });
  }, [inputValue]);

  return (
    <SearchButtonWrapper
      title={translate('search-icon-tooltip')}
      onClick={onClickAction}
      aria-label={translate('search-icon-tooltip')}
      tabIndex={0}
    >
      <FontAwesomeIcon icon={faSearch} />
    </SearchButtonWrapper>
  );
};

export default SearchButton;
