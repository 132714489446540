'use client';

import React from 'react';

import {
  // faCircleXmark,
  faSearch,
  faHistory,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { useSearchContext } from '../SearchContext';
import { parseSuggestion } from '../utils';

const Wrapper = styled.li<{ $isActive: boolean }>`
  padding: 6px 16px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  background-color: ${({ $isActive }) => ($isActive ? '#f7f7f7' : 'white')};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: #f7f7f7;
  }
`;

const Icon = styled.span`
  font-size: 1.2rem;

  svg {
    color: #ccc;
  }
`;

const SuggestionText = styled.span``;

const MAP_SUGGESTION_TYPE_TO_ICON = {
  suggestion: faSearch,
  history: faHistory,
};

const SuggestionByType = ({ suggestionObject }) => {
  const { searchQuery } = useSearchContext();

  const icon = React.useMemo(() => {
    return MAP_SUGGESTION_TYPE_TO_ICON[suggestionObject.type];
  }, [suggestionObject.type]);

  const parsedText = React.useMemo(() => {
    return parseSuggestion(suggestionObject.value, searchQuery);
  }, [suggestionObject.value, searchQuery]);

  /**
   * @todo remove, examine source
   */
  if (suggestionObject.value === 'no-type-or-slug') {
    return null;
  }

  return (
    <>
      <Icon>
        <FontAwesomeIcon icon={icon} />
      </Icon>
      <SuggestionText>{parsedText}</SuggestionText>
    </>
  );
};

const SuggesionItem = ({ suggestionObject, isActive }) => {
  const {
    goToSearchPageAction,
    setInputValue,
    // inputValue,
    // logSelectedSuggestion,
    // setActiveSuggestionIndex,
  } = useSearchContext();

  /**
   * @todo
   */
  const handleSuggestionClick = React.useCallback(() => {
    // const suggestion = suggestionObject.value;
    // run consecutively
    // setActiveSuggestionIndex(index);
    setTimeout(() => {
      goToSearchPageAction(suggestionObject.value);
    }, 1000);
    // new Promise(resolve => {
    //   setTimeout(() => {
    //     resolve(null);
    //   }, 500);
    // })
    //   .then(async () => {
    //     await logSelectedSuggestion(suggestion);
    //   })
    //   .then(() => {
    //     goToSearchPageAction();
    //   });
  }, [setInputValue, goToSearchPageAction]);

  return (
    <Wrapper onClick={handleSuggestionClick} $isActive={isActive}>
      <SuggestionByType suggestionObject={suggestionObject} />
    </Wrapper>
  );
};

export default SuggesionItem;
