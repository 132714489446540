export const theme = {
  colors: {
    white: '#ffffff',
    brand: '#fa6831',
    brandDark: '#e05d2d',
    yellow: '#F2BA0E', // TODO remove and replace with yellow400
    yellow400: '#F2BA0E',
    yellow500: '#9c8408',
    gray100: '#F5F5F5',
    gray200: '#E0E0E0',
    gray300: '#B3B3B3',
    gray400: '#8C8C8C',
    gray500: '#808080',
    gray700: '#5C5C5C',
    gray800: '#2A2A2A',
    blue400: '#40C8E8',
    blue500: '#26B4D5',
    green400: '#65C900',
    green500: '#5DAF0B',
    red300: '#E05D2D',
    red400: '#E84040',
    red500: '#CB2C2C',
    red700: '#FF0000',
    black: '#000000',
  },
  zIndex: {
    // TODO: discuss order
    modal: 40,
    list: 41,
    tooltip: 44,
    loader: 100,
  },
  text: {
    baseSize: 14,
    fontWeight: {
      normal: 'normal',
      bold: 'bold',
      900: 900,
    },
    size: {
      xs: '0.7857142857rem', // 11px
      sm: '1rem', // 14px
      md: '1.1428571429rem', // 16px
      lg: '1.2857142857rem', // 18px
      xl: '1.7142857143rem', // 24px
    },
    lineHeight: {
      xs: '1.0714285714rem', // 15px
      sm: '1.125rem', // 18px
      md: '1.4285714286rem', // 20px
      lg: '1.8571428571rem', // 26px
      xl: '2.4285714286', // 34px
    },
  },
};

export type Theme = typeof theme;

export type TextSize = keyof typeof theme.text.size;
export type TextWeight = keyof typeof theme.text.fontWeight;
export type TextLineHeight = keyof typeof theme.text.lineHeight;

export type Colors = keyof typeof theme.colors;
