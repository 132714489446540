'use client';

import React from 'react';

import styled from 'styled-components';

import SuggestionItem from './SuggestionItem';
import { useSearchContext } from '../SearchContext';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
  text-align: left;
`;

const SuggestionsList = () => {
  const { suggestions, activeSuggestionIndex } = useSearchContext();

  return (
    <List>
      {suggestions.map((suggestionObject, index) => (
        <SuggestionItem
          key={index}
          suggestionObject={suggestionObject}
          isActive={index === activeSuggestionIndex}
          // index={index}
        />
      ))}
    </List>
  );
};

export default SuggestionsList;
