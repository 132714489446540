'use client';

import React from 'react';

import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { useSearchContext } from '../SearchContext';

const ClearButtonWrapper = styled.div`
  padding: 0.5em;
  justify-content: center;
  color: var(--Gray, #808080);
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }

  svg {
    font-size: 1.375rem;
  }
`;

const ClearButton = () => {
  const { inputValue, clearInput } = useSearchContext();

  if (!inputValue) return null;

  return (
    <ClearButtonWrapper
      title="Clear search query"
      onClick={clearInput}
      aria-label="Clear the search input"
      tabIndex={0}
    >
      <FontAwesomeIcon icon={faTimesCircle} />
    </ClearButtonWrapper>
  );
};

export default ClearButton;
