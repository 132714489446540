import React from 'react';

export const useResetActiveSuggestionIndex = ({
  searchQuery,
  setActiveSuggestionIndex,
}) => {
  React.useEffect(() => {
    setActiveSuggestionIndex(null);
  }, [searchQuery, setActiveSuggestionIndex]);
};

export default useResetActiveSuggestionIndex;
