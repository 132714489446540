import { useEffect, useState } from 'react';

import { log } from '@/utils/logger';

const url = '/search-index/stream-suggestions';

const processStreamData = data => {
  const unpackedData = data.data;

  if (Array.isArray(unpackedData) && unpackedData.length > 0) {
    return unpackedData;
  }

  return false;
};

export default function useEventSource({ query, locale }) {
  const [data, setData] = useState<Suggestions>([]);

  useEffect(() => {
    if (query) {
      setData([]);
      const eventSource = new EventSource(
        `${url}?query=${query}&locale=${locale}`
      );

      eventSource.onmessage = function (event) {
        const newData = JSON.parse(event.data);
        const processedData = processStreamData(newData);

        if (processedData) {
          setData(prevData => [...prevData, ...processedData]);
        }
      };

      eventSource.onerror = function (error) {
        log.error(error);
        eventSource.close();
      };

      return () => {
        eventSource.close();
      };
    }
  }, [query, locale]);

  return data;
}
